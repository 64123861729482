document.addEventListener("DOMContentLoaded", function () {
	var contentContainers = document.querySelectorAll(".content-container");

	contentContainers.forEach(function (container) {
		var textContainer = container.querySelector(".text-container");


		var readMore = container.querySelector(".readMore");
		var isClicked = false; // Flag to track the toggle state

        //console.log("textContainer: ",textContainer );
        //console.log("readMore: ",readMore );

		// Function to toggle styles
		function toggleStyles() {


			if (isClicked == false) {
				//hide readMore
				readMore.style.display = "none";

				//everything that needs to get applied
				/*textContainer.style.background = "none";
				textContainer.style.color = "initial";
				textContainer.style.webkitTextFillColor = "#212121";
				textContainer.style.width = "100%";*/
				textContainer.style.height = "100%";
				textContainer.style.overflow = "";



				textContainer.style.overflow = "";


				textContainer.classList.remove("text-container");
				isClicked = true;
			} else {



                textContainer.classList.add("text-container");
                textContainer.classList.add("headingsNormal");
                textContainer.style.overflow = "";
                textContainer.style.height = "400px";
                readMore.style.display = "";
               
				isClicked = false;
			}

		}
        
        if(textContainer != null && readMore != null)
		    container.addEventListener("click", toggleStyles);
	});
});
