// This is the scss entry file
import "../styles/index.scss";
import "../styles/pages/home.scss";
import "../styles/pages/poseg.scss";
import "../styles/pages/price.scss";

import "../styles/components/form.scss";
import "../styles/components/menuMobile.scss";

import "./helper/poseg.js";

// We can import other JS file as we like
//import "../components/sidebar";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

// HOMEPAGE SWIPER:
const swiper = new Swiper(".swiper", {
    slidesPerView: 1,
    spaceBetween: 300,
    loop: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

window.document.addEventListener("DOMContentLoaded", function () {
    //window.console.log("dom ready 1");
});

const HIDE_IMAGES = false;
if (process.env.NODE_ENV === "development" && HIDE_IMAGES) {
    document.addEventListener("DOMContentLoaded", function () {
        const images = document.querySelectorAll("img");
        images.forEach((img) => {
            // Create a wrapper div
            const wrapper = document.createElement("div");
            wrapper.style.position = "relative";
            wrapper.style.display = "inline-block"; // Or 'block' depending on your layout

            // Create a gray placeholder
            const placeholder = document.createElement("div");
            placeholder.style.backgroundColor = "gray";
            placeholder.style.width = "100%";
            placeholder.style.height = "100%";
            placeholder.style.position = "absolute";
            placeholder.style.top = "0";
            placeholder.style.left = "0";

            // Insert the wrapper into the DOM, before the img
            img.parentNode.insertBefore(wrapper, img);

            // Move the img inside the wrapper, and add the placeholder
            wrapper.appendChild(img);
            wrapper.appendChild(placeholder);

            // Set the img to opacity 0
            img.style.opacity = "0";
        });
    });
}
